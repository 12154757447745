@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
html,
body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden; }
  @media only screen and (max-width: 768px) {
    html,
    body {
      background: unset; } }

body {
  padding-top: 230px;
  background-color: #FFFFFF;
  /*@media only screen and (min-width: 992px) and (max-width: 1119px) {
        padding-top: 165px;
    }*/ }
  @media only screen and (max-width: 767px) {
    body {
      padding-top: 140px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    body {
      padding-top: 175px; } }

@media only screen and (min-width: 1350px) {
  .container {
    max-width: 1350px; } }

a {
  text-decoration: none !important; }

.text-bold {
  font-weight: 600; }

button {
  cursor: pointer; }

.article-col {
  flex: 0 0 20%;
  max-width: 20%; }
  @media only screen and (max-width: 767px) {
    .article-col {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .article-col {
      flex: 0 0 50%;
      max-width: 50%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .article-col {
      flex: 0 0 33.3333333%;
      max-width: 33.3333333%; } }

.form-container {
  padding: 0; }

.content-wrap {
  padding: 0; }

.breadcrumb {
  background-color: transparent;
  padding: 0;
  padding-bottom: 38px;
  margin: 0; }
  .breadcrumb > li {
    font-size: 18px; }
    .breadcrumb > li::after {
      content: " / ";
      padding: 0 6px; }
    .breadcrumb > li a {
      color: #333; }
    .breadcrumb > li:last-child::after {
      content: ''; }
    .breadcrumb > li:last-child a {
      color: #EFAD00;
      font-weight: 600; }
  @media only screen and (max-width: 768px) {
    .breadcrumb {
      display: none; } }

.section {
  padding: 20px 0;
  float: left;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .section {
      padding: 10px 0; } }
  .section h2 {
    font-size: 54px;
    font-weight: 400;
    margin-bottom: 22px;
    font-family: 'Arvo', serif; }
    .section h2 > span {
      font-weight: 400; }
    @media only screen and (max-width: 768px) {
      .section h2 {
        font-size: 20px; } }
  .section .header-top {
    display: none; }
  .section .home-wrap .header {
    display: none; }
  .section .home-wrap .contact-phone {
    display: none; }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(35, 38, 40, 0.9);
  z-index: 9999; }
  .lightbox .mdi-close {
    position: fixed;
    top: 16px;
    right: 16px;
    font-size: 48px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 99999; }
  .lightbox .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh; }
    .lightbox .lightbox-item img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
  .lightbox .carousel-control-prev-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }
  .lightbox .carousel-control-next-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }

.section-articles a {
  text-decoration: none; }

.error-text {
  margin: 16px 0;
  color: #DB3838; }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem-calc(5); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #EFAD00;
  border-radius: rem-calc(5);
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #EFAD00;
  cursor: pointer; }

.styled-numbers {
  margin-bottom: 20px;
  list-style: none; }
  .styled-numbers span {
    font-weight: 600;
    color: #EFAD00;
    margin-right: 8px; }

.text-primary {
  color: #EFAD00 !important; }

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #FFFFFF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.05); }
  .cookies P {
    color: #333;
    margin-bottom: 0; }
  .cookies button {
    margin: 0 16px;
    border: 0;
    background-color: #333;
    color: #FFFFFF;
    padding: 6px 16px; }
    .cookies button:hover {
      background-color: #EFAD00; }
  .cookies a {
    color: #333;
    font-weight: 600;
    font-size: 18px; }
  @media only screen and (max-width: 768px) {
    .cookies {
      flex-direction: column;
      height: unset;
      padding: 32px 16px; }
      .cookies button {
        margin-top: 16px; } }

.pop-up-messages {
  position: fixed;
  right: 32px;
  top: 275px;
  z-index: 999999;
  width: 400px; }
  @media only screen and (max-width: 768px) {
    .pop-up-messages {
      width: 90%;
      margin-left: 5%;
      top: 0;
      margin-top: 50px;
      left: 0;
      right: unset; } }
  .pop-up-messages > div {
    padding: 42px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    margin-bottom: 16px;
    position: relative;
    cursor: pointer; }
    .pop-up-messages > div .hide {
      color: #FFFFFF;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 22px;
      cursor: pointer; }
    .pop-up-messages > div > p {
      color: #FFFFFF;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .pop-up-messages > div > p::before {
        content: ' ';
        display: block;
        width: 10px;
        height: 20px;
        border-bottom: 3px solid #97ef9d;
        border-right: 3px solid #97ef9d;
        transform: rotate(45deg);
        float: left;
        margin-right: 16px; }
    .pop-up-messages > div .error::before {
      display: none; }
  .pop-up-messages .hide-message {
    animation-name: hide-message;
    animation-duration: 1s;
    transform: translate3d(110%, 0, 0); }

@keyframes hide-message {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(110%, 0, 0); } }
  .pop-up-messages .mdi-close {
    color: #DB3838;
    font-size: 24px;
    margin-right: 8px; }

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 520px;
  margin-left: -400px;
  margin-top: -260px;
  -webkit-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 21;
  animation-name: rotate;
  animation-duration: 1s; }

@keyframes rotate {
  from {
    top: 0; }
  to {
    top: 50%; } }
  @media only screen and (max-width: 768px) {
    .popup {
      width: 90%;
      margin-left: 5%;
      left: 0;
      height: 400px; } }
  .popup .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .popup .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 64px; }
    @media only screen and (max-width: 768px) {
      .popup .content {
        padding: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; } }
    .popup .content .coupon {
      width: 425px;
      transform: rotate(-6deg);
      margin-bottom: 40px; }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          width: 70%; } }
      @media only screen and (max-width: 768px) {
        .popup .content .coupon {
          margin-bottom: 10px; } }
    .popup .content h2 {
      font-size: 46px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h2 {
          font-size: 24px; } }
    .popup .content h3 {
      font-size: 24px;
      color: #FFFFFF; }
      @media only screen and (max-width: 768px) {
        .popup .content h3 {
          font-size: 18px; } }
    .popup .content h6 {
      font-size: 15px;
      color: #CCCCCC;
      font-style: italic;
      margin-bottom: 20px; }
      @media only screen and (max-width: 768px) {
        .popup .content h6 {
          font-size: 12px; } }
    .popup .content .button {
      position: absolute;
      bottom: 120px;
      left: 50%;
      margin-left: -120px;
      background-color: #000; }

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 19;
  background-color: rgba(0, 0, 0, 0.3); }

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px; }

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #EFAD00;
  border-color: #EFAD00 transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite; }

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.not-found-content {
  text-align: center;
  padding: 38px 0; }
  .not-found-content h1 {
    font-size: 100px !important; }
  .not-found-content h2 {
    font-size: 32px !important;
    margin-bottom: 32px; }

article {
  position: relative; }
  article .discount {
    position: absolute;
    right: 0;
    top: 20px;
    background-color: #B3DE5B;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 6px 0 0 6px;
    font-weight: 600;
    font-size: 18px; }
    @media only screen and (max-width: 768px) {
      article .discount {
        right: 0px; } }

.list-article .discount {
  right: 0; }

.side-banner img {
  width: 100%;
  margin-bottom: 30px; }

.discount-detail {
  position: absolute;
  right: 15px;
  top: 20px;
  background-color: #B3DE5B;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 6px 0 0 6px;
  font-weight: 600;
  font-size: 18px;
  z-index: 19; }

.news-detail h1 {
  margin-bottom: 24px; }

.news-detail img {
  width: 100%;
  margin-bottom: 16px; }

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: cssAnimation  0.7s forwards;
  animation: cssAnimation  0.7s forwards;
  pointer-events: none; }

@keyframes cssAnimation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes cssAnimation {
  0% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.find-client-wrap {
  margin-bottom: 32px;
  position: relative; }
  .find-client-wrap ul {
    position: absolute;
    top: 92px;
    background-color: #FFFFFF;
    width: 100%;
    padding: 32px 16px;
    list-style: none;
    z-index: 9999;
    border: 1px solid #D1D1D1; }
    .find-client-wrap ul > li {
      padding: 8px 16px; }
      .find-client-wrap ul > li:hover {
        background-color: #F5F5F5;
        cursor: pointer; }

.delete-client {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }
  .delete-client .isvg {
    cursor: pointer; }

.deliveryBox-container {
  border: 1px solid #EFAD00;
  margin-bottom: 32px; }
  .deliveryBox-container p {
    margin-bottom: 0; }
  .deliveryBox-container .text {
    padding: 15px; }

.deliveryBox {
  background: #EFAD00;
  color: white;
  padding: 8px 16px; }
  .deliveryBox h6 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0; }

.pagination {
  float: left;
  margin: 20px 0; }
  .pagination > li {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 10px;
    min-width: 40px; }
    .pagination > li a {
      color: #333;
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 17px;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      padding: 6px 6px; }
    .pagination > li:hover {
      color: #FFFFFF;
      text-decoration: none;
      background-color: #EFAD00; }
      .pagination > li:hover a {
        text-decoration: none;
        color: #FFFFFF; }
  .pagination .break-me:hover {
    background-color: #E5E5E5;
    cursor: default; }
    .pagination .break-me:hover a {
      color: #FFFFFF; }
  .pagination .active {
    color: #FFFFFF;
    background-color: #2F629C; }
    .pagination .active a {
      color: #FFFFFF; }
  .pagination .previous {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination .previous::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid #333;
      border-left: 2px solid #333;
      padding: 4px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 13px;
      left: 18px; }
    .pagination .previous:hover::after {
      border-bottom: 2px solid #FFFFFF;
      border-left: 2px solid #FFFFFF; }
  .pagination .next {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .pagination .next::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid #333;
      border-left: 2px solid #333;
      padding: 4px;
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 13px;
      left: 14px; }
    .pagination .next:hover::after {
      border-bottom: 2px solid #FFFFFF;
      border-left: 2px solid #FFFFFF; }

.button {
  color: #FFFFFF;
  background-color: #333;
  padding: 8px 28px;
  text-decoration: none;
  outline: none;
  border: none; }
  .button .isvg {
    margin-right: 8px; }

.button-black {
  background-color: #333; }

.button-right {
  float: right; }

.big-button {
  height: 50px; }

header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  transition: top 0.3s;
  border-bottom: 1px solid #D9D9D9;
  background-color: #ffffff;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  header .contact-wrap {
    background-color: #f5f5f5;
    padding: 12px 0; }
    @media only screen and (max-width: 767px) {
      header .contact-wrap {
        display: none; } }
    header .contact-wrap .col-lg-12 {
      display: flex;
      justify-content: space-between; }
      header .contact-wrap .col-lg-12 .contact {
        display: flex; }
        header .contact-wrap .col-lg-12 .contact > div {
          margin-right: 27px;
          font-size: 14px; }
          header .contact-wrap .col-lg-12 .contact > div svg {
            margin-right: 10px; }
            header .contact-wrap .col-lg-12 .contact > div svg path {
              fill: #333; }
          header .contact-wrap .col-lg-12 .contact > div a {
            color: #7B7B93; }
      header .contact-wrap .col-lg-12 .social a {
        margin: 0 10px; }
  header .mobile-header {
    padding: 10px 0;
    display: none; }
    @media only screen and (max-width: 1023px) {
      header .mobile-header {
        display: block; } }
    header .mobile-header .mobile-actions {
      display: none; }
      @media only screen and (max-width: 767px) {
        header .mobile-header .mobile-actions {
          display: flex;
          align-items: center; }
          header .mobile-header .mobile-actions button {
            border: 0;
            outline: none;
            background-color: transparent; }
          header .mobile-header .mobile-actions .cart {
            position: relative; }
            header .mobile-header .mobile-actions .cart .cart-count {
              background-color: #EFAD00;
              border-radius: 50%;
              width: 18px;
              height: 18px;
              color: #FFFFFF;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: -10px;
              left: 22px; }
            header .mobile-header .mobile-actions .cart svg {
              fill: none; }
              header .mobile-header .mobile-actions .cart svg path, header .mobile-header .mobile-actions .cart svg circle {
                stroke: #333;
                stroke-width: 2; } }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        header .mobile-header .mobile-actions {
          display: flex;
          align-items: center; }
          header .mobile-header .mobile-actions button {
            border: 0;
            outline: none;
            background-color: transparent; }
          header .mobile-header .mobile-actions .cart svg {
            fill: none; }
            header .mobile-header .mobile-actions .cart svg path, header .mobile-header .mobile-actions .cart svg circle {
              stroke: #333;
              stroke-width: 2; } }
    header .mobile-header .logo {
      display: flex;
      align-items: center;
      position: relative; }
      header .mobile-header .logo input {
        display: none; }
      header .mobile-header .logo .search {
        display: none; }
      header .mobile-header .logo > svg {
        display: none; }
      @media only screen and (max-width: 1023px) {
        header .mobile-header .logo .desktop-logo {
          display: none; }
        header .mobile-header .logo input {
          display: block;
          background-color: rgba(245, 245, 245, 0.1);
          padding: 5px 20px;
          width: 100%;
          position: relative;
          border: 2px solid #333;
          outline: none; }
          header .mobile-header .logo input:focus {
            border: 2px solid #EFAD00;
            background-color: rgba(245, 245, 245, 0.8); }
        header .mobile-header .logo .search {
          display: block;
          border: 0;
          outline: none;
          position: absolute;
          top: 0px;
          right: 15px;
          background-color: transparent;
          background-color: #333;
          padding: 5px 15px; }
          header .mobile-header .logo .search svg {
            width: 18px; }
        header .mobile-header .logo > .isvg svg {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -30px;
          margin-top: -40px;
          width: 60px; } }
    header .mobile-header .hamburger {
      display: none;
      z-index: 999; }
      @media only screen and (max-width: 767px) {
        header .mobile-header .hamburger {
          display: flex;
          align-items: center;
          justify-content: center; } }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        header .mobile-header .hamburger {
          display: flex;
          align-items: center;
          justify-content: center; } }
      header .mobile-header .hamburger button {
        width: 28px;
        height: 20px;
        background-color: transparent;
        border: 0;
        outline: none;
        padding: 0;
        position: relative;
        transition: transform 0.3s; }
        header .mobile-header .hamburger button > div {
          width: 100%;
          height: 4px;
          background-color: #333;
          border-radius: 3px;
          transition: transform 0.7s;
          transform: rotate(0deg); }
          header .mobile-header .hamburger button > div:nth-child(2) {
            position: absolute;
            top: 50%;
            margin-top: -2px;
            left: 0;
            width: 100%;
            background-color: #333;
            transition: background-color 1.5s !important; }
          header .mobile-header .hamburger button > div:nth-child(1) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transform-origin: top right; }
          header .mobile-header .hamburger button > div:nth-child(3) {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            transform-origin: bottom right; }
    header .mobile-header .hamburger-animation button {
      transform: translateX(240px);
      position: relative;
      height: 28px; }
      header .mobile-header .hamburger-animation button > div {
        background-color: #FFFFFF;
        margin-top: 0px;
        display: block; }
        header .mobile-header .hamburger-animation button > div:nth-child(2) {
          background-color: transparent !important; }
        header .mobile-header .hamburger-animation button > div:nth-child(1) {
          transform: rotate(-45deg);
          transform-origin: top right;
          top: 1px; }
        header .mobile-header .hamburger-animation button > div:nth-child(3) {
          transform: rotate(45deg);
          transform-origin: bottom right;
          bottom: 4px;
          width: 100% !important; }

@keyframes hamburger-animation1 {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(-37deg); } }

@keyframes hamburger-animation2 {
  from {
    transform: rotate(0); }
  to {
    transform: rotate(37deg); } }
  header .header-top {
    padding-bottom: 17px;
    border-bottom: 1px solid #D9D9D9; }
    @media only screen and (max-width: 1023px) {
      header .header-top {
        padding-bottom: 10px;
        display: none; } }
    header .header-top .header-top-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 17px; }
      header .header-top .header-top-container .contact {
        display: flex;
        align-items: center;
        color: #7B7B93;
        font-size: 14px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          header .header-top .header-top-container .contact {
            display: none; } }
        header .header-top .header-top-container .contact > div {
          margin-right: 32px;
          white-space: nowrap; }
          header .header-top .header-top-container .contact > div svg {
            margin-right: 10px; }
        header .header-top .header-top-container .contact a {
          color: #7B7B93; }
      @media only screen and (max-width: 1023px) {
        header .header-top .header-top-container .logo {
          display: none; } }
      header .header-top .header-top-container .search {
        width: 430px;
        position: relative; }
        @media only screen and (max-width: 1023px) {
          header .header-top .header-top-container .search {
            display: none; } }
        header .header-top .header-top-container .search input {
          background-color: #F5F5F5;
          border: 0;
          outline: none;
          padding: 14px 20px 14px 20px;
          width: 100%;
          z-index: 15 !important;
          position: relative; }
        header .header-top .header-top-container .search button {
          background-color: #333;
          border: 0;
          outline: none;
          color: #FFFFFF;
          padding: 10px 22px 10px 27px;
          position: absolute;
          top: 5px;
          right: 5px;
          font-size: 14px;
          font-weight: 600;
          z-index: 16; }
          header .header-top .header-top-container .search button svg {
            margin-left: 18px; }
        header .header-top .header-top-container .search .search-results {
          position: absolute;
          background-color: #F5F5F5;
          z-index: 9;
          padding: 35px 20px;
          width: 100%;
          margin-top: -30px; }
          header .header-top .header-top-container .search .search-results h6 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 30px; }
          header .header-top .header-top-container .search .search-results ul {
            list-style: none;
            margin: 0;
            padding: 0; }
            header .header-top .header-top-container .search .search-results ul li {
              display: flex;
              align-items: center;
              margin-bottom: 10px; }
              header .header-top .header-top-container .search .search-results ul li img {
                width: 64px;
                height: 64px;
                object-fit: cover;
                margin-right: 10px; }
              header .header-top .header-top-container .search .search-results ul li a {
                color: #333;
                display: flex;
                align-items: center;
                font-size: 14px; }
      header .header-top .header-top-container .buttons {
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 1023px) {
          header .header-top .header-top-container .buttons {
            display: none; } }
        header .header-top .header-top-container .buttons .login {
          border: 0;
          background-color: transparent;
          outline: none;
          font-size: 16px;
          color: #333;
          white-space: nowrap; }
        header .header-top .header-top-container .buttons .register {
          background-color: #333;
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          border: 0;
          outline: none;
          padding: 9px 18px;
          white-space: nowrap;
          margin-left: 20px; }
        header .header-top .header-top-container .buttons .cart {
          background-color: #F5F5F5;
          font-size: 16px;
          font-weight: 500;
          color: #333;
          border: 0;
          outline: none;
          padding: 9px 18px;
          white-space: nowrap;
          margin-left: 20px;
          position: relative; }
          header .header-top .header-top-container .buttons .cart .cart-count {
            background-color: #EFAD00;
            border-radius: 50%;
            width: 18px;
            height: 18px;
            color: #FFFFFF;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 2px;
            left: 35px; }
          header .header-top .header-top-container .buttons .cart svg {
            margin-right: 10px;
            fill: none;
            stroke-width: 2; }
            header .header-top .header-top-container .buttons .cart svg path {
              stroke: #333; }
            header .header-top .header-top-container .buttons .cart svg circle {
              stroke: #333; }
  header ::-webkit-scrollbar {
    width: 5px;
    height: 5px; }
  header ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 5px; }
  header ::-webkit-scrollbar-thumb {
    background: #EFAD00;
    border-radius: 5px;
    cursor: pointer; }
  header ::-webkit-scrollbar-thumb:hover {
    background: #EFAD00;
    cursor: pointer; }
  header .navigation-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 11px; }
    @media only screen and (max-width: 1023px) {
      header .navigation-wrap {
        overflow-x: scroll;
        margin-top: 10px; } }
    header .navigation-wrap ul {
      list-style: none;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      padding: 0; }
      @media only screen and (max-width: 1023px) {
        header .navigation-wrap ul {
          padding: 0; } }
      header .navigation-wrap ul li {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 500;
        padding: 6px 20px; }
        header .navigation-wrap ul li a {
          color: #333; }
        header .navigation-wrap ul li.active {
          background-color: #333;
          color: #FFFFFF; }
          header .navigation-wrap ul li.active a {
            color: #FFFFFF; }
      header .navigation-wrap ul .dropdown > button {
        border: 0 !important;
        background-color: transparent !important;
        color: #333 !important;
        box-shadow: none !important;
        display: flex;
        align-items: center; }
        header .navigation-wrap ul .dropdown > button::after {
          all: unset;
          content: ' ';
          width: 7px;
          height: 7px;
          border-bottom: 2px solid #333;
          border-right: 2px solid #333;
          transform: rotate(45deg);
          display: block;
          margin-left: 15px;
          margin-top: -4px; }
      header .navigation-wrap ul .dropdown .dropdown-menu button {
        color: #333; }
        header .navigation-wrap ul .dropdown .dropdown-menu button a {
          color: #333; }
        header .navigation-wrap ul .dropdown .dropdown-menu button svg {
          width: 24px;
          margin-right: 10px; }
  header .mobile-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-110%); }
    header .mobile-menu-wrap .overlay {
      background-color: rgba(31, 31, 31, 0.8);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer; }
    header .mobile-menu-wrap .mobile-menu {
      width: 300px;
      background-color: #FFFFFF;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      transition: transform 0.3s;
      transform: translateX(-110%);
      overflow-y: auto; }
      header .mobile-menu-wrap .mobile-menu .user-info {
        background-color: #333;
        padding: 22px; }
        header .mobile-menu-wrap .mobile-menu .user-info svg {
          width: 32px;
          height: 36px;
          margin-bottom: 12px; }
        header .mobile-menu-wrap .mobile-menu .user-info h6 {
          color: #FFFFFF;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px; }
        header .mobile-menu-wrap .mobile-menu .user-info p {
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
          margin-bottom: 18px; }
        header .mobile-menu-wrap .mobile-menu .user-info .buttons {
          display: flex;
          justify-content: space-between; }
          header .mobile-menu-wrap .mobile-menu .user-info .buttons button {
            font-size: 12px;
            color: #FFFFFF;
            background-color: transparent;
            border: 1px solid #FFFFFF;
            border-radius: 25px;
            padding: 1px 10px; }
        header .mobile-menu-wrap .mobile-menu .user-info .buttons-login {
          justify-content: flex-start; }
          header .mobile-menu-wrap .mobile-menu .user-info .buttons-login button {
            margin-right: 15px; }
      header .mobile-menu-wrap .mobile-menu h5 {
        padding-left: 24px;
        color: #EFAD00;
        margin-top: 20px; }
      header .mobile-menu-wrap .mobile-menu .categories-tree {
        list-style: none;
        padding: 0;
        margin: 0;
        padding-left: 24px; }
        header .mobile-menu-wrap .mobile-menu .categories-tree li {
          padding: 3px 0px;
          font-size: 16px;
          font-weight: 400;
          color: #333; }
          header .mobile-menu-wrap .mobile-menu .categories-tree li a {
            color: #333;
            text-decoration: none; }
          header .mobile-menu-wrap .mobile-menu .categories-tree li .active {
            color: #EFAD00;
            font-weight: 600; }
          header .mobile-menu-wrap .mobile-menu .categories-tree li .head {
            display: flex;
            align-items: center;
            justify-content: space-between; }
            header .mobile-menu-wrap .mobile-menu .categories-tree li .head button {
              border: 0;
              outline: none;
              background-color: transparent; }
          header .mobile-menu-wrap .mobile-menu .categories-tree li ul {
            list-style: none;
            position: relative;
            padding-left: 20px;
            margin: 0; }
            header .mobile-menu-wrap .mobile-menu .categories-tree li ul::after {
              content: ' ';
              position: absolute;
              top: 0px;
              left: 0px;
              border-left: 1px solid #C7D1E6;
              width: 20px;
              height: 100%;
              height: calc(100% - 14px); }
            header .mobile-menu-wrap .mobile-menu .categories-tree li ul li {
              position: relative; }
              header .mobile-menu-wrap .mobile-menu .categories-tree li ul li::after {
                content: ' ';
                position: absolute;
                top: 14px;
                left: -20px;
                height: 2px;
                width: 10px;
                border-bottom: 1px solid #C7D1E6; }
        header .mobile-menu-wrap .mobile-menu .categories-tree > li {
          font-size: 16px;
          font-weight: 600;
          color: #333; }
  header .mobile-menu-open {
    transform: translateX(0); }
    header .mobile-menu-open .mobile-menu {
      transform: translateX(0); }

.scroll-header {
  background-color: #FFFFFF; }
  @media only screen and (max-width: 1023px) {
    .scroll-header {
      padding-bottom: 0; } }
  .scroll-header .header-top {
    background-color: #FFFFFF; }
    @media only screen and (max-width: 1023px) {
      .scroll-header .header-top {
        display: none; } }

.hide-header {
  top: -250px; }

.mobile-left-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(-110%, 0, 0);
  -moz-transform: translate3d(-110%, 0, 0);
  transform: translate3d(-110%, 0, 0); }
  .mobile-left-menu .content {
    width: 300px;
    height: 100%;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(-110%, 0, 0);
    -moz-transform: translate3d(-110%, 0, 0);
    transform: translate3d(-110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    cursor: pointer; }
    .mobile-left-menu .content .user-area {
      width: 100%;
      height: 160px;
      background-color: #EFAD00;
      padding: 30px 30px;
      position: relative; }
      .mobile-left-menu .content .user-area .currency-mobile {
        position: absolute;
        top: 16px;
        right: 16px; }
        .mobile-left-menu .content .user-area .currency-mobile a {
          margin: 0 5px;
          color: #FFFFFF; }
      .mobile-left-menu .content .user-area .user-image {
        width: 64px;
        height: 64px;
        background-color: rgba(255, 255, 255, 0.5);
        margin-bottom: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .mobile-left-menu .content .user-area .user-image svg {
          fill: #FFFFFF; }
      .mobile-left-menu .content .user-area p {
        color: #FFFFFF;
        margin-bottom: 0;
        font-size: 14px; }
        .mobile-left-menu .content .user-area p:nth-child(2) {
          font-weight: 600; }
        .mobile-left-menu .content .user-area p a {
          color: #FFFFFF; }
    .mobile-left-menu .content ul {
      padding: 25px;
      list-style: none; }
      .mobile-left-menu .content ul > li {
        padding: 8px 0;
        font-weight: 600;
        color: #333; }
        .mobile-left-menu .content ul > li a {
          color: #333;
          display: flex;
          align-items: center; }
        .mobile-left-menu .content ul > li svg {
          height: 24px;
          width: 24px;
          margin-right: 10px; }
        .mobile-left-menu .content ul > li i {
          font-size: 24px;
          margin-right: 10px; }

.mobile-left-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-left-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.mobile-right-menu {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(110%, 0, 0);
  -moz-transform: translate3d(110%, 0, 0);
  transform: translate3d(110%, 0, 0);
  cursor: pointer; }
  .mobile-right-menu .content {
    width: 300px;
    height: 100%;
    float: right;
    background-color: #FFFFFF;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(110%, 0, 0);
    -moz-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
    -webkit-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    -moz-transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    transition: transform 0.7s cubic-bezier(0.25, 1, 0.2, 1);
    position: relative; }
    .mobile-right-menu .content h3 {
      background-color: #EFAD00;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .mobile-right-menu .content .close {
      position: absolute;
      top: 6px;
      right: 6px;
      color: #FFFFFF;
      font-size: 32px; }
      .mobile-right-menu .content .close svg {
        fill: #FFFFFF; }
    .mobile-right-menu .content > ul {
      list-style: none;
      padding: 20px 16px;
      margin: 0;
      border-top: none;
      float: left;
      width: 100%;
      height: 100%;
      overflow: auto; }
      .mobile-right-menu .content > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .mobile-right-menu .content > ul > li::after {
          /* content: " ";
                    display: block;
                    border-top: 2px solid $gray;
                    border-right: 2px solid $gray;
                    width: 10px;
                    height: 10px;
                    transform: rotate(45deg);
                    float: right;
                    margin-top: 16px;
                    margin-right: 4px;*/ }
        .mobile-right-menu .content > ul > li a {
          float: left;
          color: #333;
          padding: 7px 0; }
        .mobile-right-menu .content > ul > li > a {
          font-size: 18px; }
        .mobile-right-menu .content > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .mobile-right-menu .content > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #EFAD00;
            border-left: 2px solid #EFAD00;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .mobile-right-menu .content > ul > li ul li {
            width: 100%;
            float: left; }
      .mobile-right-menu .content > ul .active {
        position: relative; }
        .mobile-right-menu .content > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }

.mobile-right-menu-open {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
  .mobile-right-menu-open .content {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

/*
@include mQ(767px, 320px) {
    .header-top {
        display: none;
    }
    .home-wrap {
        .header {
            display: none;
        }
        .contact-phone {
            display: none;
        }
    }
    .navigation {
        display: none;
    }
}

@include mQ(1024px, 768px) {
    .header-top {
        display: none;
    }
    .home-wrap {
        .header {
            display: none;
        }
        .contact-phone {
            display: none;
        }
    }
    .navigation {
        display: none;
    }
}*/
.search-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #EFAD00; }
  .search-container .input {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    margin: 0 auto;
    width: 100%;
    margin-top: -15px; }
    .search-container .input button {
      background-color: transparent;
      border: 0;
      outline: none; }
    .search-container .input input {
      background-color: transparent;
      border: 0;
      border-bottom: 2px solid #FFFFFF;
      color: #FFFFFF;
      outline: none; }
  .search-container .close {
    position: absolute;
    right: 16px;
    padding: 16px;
    font-size: 32px;
    background-color: transparent;
    border: 0;
    outline: none; }
    .search-container .close svg {
      fill: #FFFFFF; }
    .search-container .close i {
      color: #FFFFFF; }

.section-instagram {
  padding-top: 50px;
  margin-bottom: 55px;
  border-top: 1px solid #D9D9D9;
  position: relative;
  float: left;
  width: 100%; }
  @media only screen and (max-width: 767px) {
    .section-instagram {
      padding-top: 30px; } }
  .section-instagram .title {
    text-align: center; }
    .section-instagram .title h3 {
      font-size: 32px;
      color: #333;
      font-weight: 400;
      margin-bottom: 15px;
      text-transform: uppercase; }
      .section-instagram .title h3 span {
        font-weight: 700; }
      @media only screen and (max-width: 767px) {
        .section-instagram .title h3 {
          font-size: 24px; } }
    .section-instagram .title h6 {
      font-size: 20px;
      color: #EFAD00;
      font-weight: 500;
      margin-bottom: 40px; }
      .section-instagram .title h6 a {
        color: #EFAD00; }
      .section-instagram .title h6 svg {
        margin-right: 10px; }
        .section-instagram .title h6 svg path {
          fill: #EFAD00; }
      @media only screen and (max-width: 767px) {
        .section-instagram .title h6 {
          font-size: 16px; } }
  .section-instagram .image img {
    width: 100%; }
    @media only screen and (max-width: 1023px) {
      .section-instagram .image img {
        margin-bottom: 30px; } }

.section-newsletter {
  padding: 30px 0;
  position: relative;
  position: relative;
  float: left;
  width: 100%; }
  .section-newsletter .content {
    background-color: #333;
    position: relative;
    padding: 29px 40px; }
    @media only screen and (max-width: 1023px) {
      .section-newsletter .content {
        padding: 29px 15px; } }
    .section-newsletter .content svg {
      position: absolute;
      left: 50%;
      top: 0;
      margin-left: -259px; }
  @media only screen and (max-width: 1023px) {
    .section-newsletter {
      padding: 22px 30px;
      text-align: center; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .section-newsletter {
      padding: 22px 30px;
      text-align: center; } }
  .section-newsletter h3 {
    color: #FFFFFF;
    font-size: 34px;
    font-weight: 400;
    margin-bottom: 0px; }
    .section-newsletter h3 span {
      font-weight: 700; }
    @media only screen and (max-width: 1023px) {
      .section-newsletter h3 {
        margin-bottom: 16px;
        font-size: 20px; } }
  .section-newsletter p {
    color: #333;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 400; }
    @media only screen and (max-width: 1023px) {
      .section-newsletter p {
        font-size: 12px;
        margin-bottom: 21px; } }
  .section-newsletter .err {
    color: #DB353D;
    font-size: 14px;
    padding: 8px 20px; }
  .section-newsletter .row {
    align-items: center; }
  .section-newsletter .input {
    padding: 5px 5px 5px 30px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .section-newsletter .input input {
      border: 0;
      outline: none;
      width: 100%; }
      @media only screen and (max-width: 1023px) {
        .section-newsletter .input input {
          font-size: 12px; } }
    .section-newsletter .input button {
      margin-left: 30px;
      background-color: #EFAD00;
      color: #FFFFFF;
      border: 0;
      outline: none;
      display: flex;
      align-items: center;
      padding: 14px 27px 14px 40px;
      transition: background-color 0.3s;
      font-size: 18px;
      font-weight: 500;
      white-space: nowrap; }
      @media only screen and (max-width: 1023px) {
        .section-newsletter .input button {
          font-size: 12px;
          padding: 12px 15px;
          min-width: 110px;
          margin-left: 15px; }
          .section-newsletter .input button svg {
            display: none; } }
      .section-newsletter .input button:hover {
        background-color: #333; }
      .section-newsletter .input button svg {
        margin-left: 16px; }
        @media only screen and (max-width: 1023px) {
          .section-newsletter .input button svg {
            margin-left: 10px; } }

footer {
  padding-top: 62px;
  position: relative;
  padding-bottom: 16px;
  position: relative;
  float: left;
  width: 100%;
  position: relative; }
  @media only screen and (max-width: 767px) {
    footer {
      padding-left: 30px;
      padding-right: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    footer {
      padding-left: 30px;
      padding-right: 30px; } }
  footer .copyright1 {
    margin-top: 30px;
    font-size: 16px;
    color: #1A2340; }
    footer .copyright1 p {
      margin-bottom: 0; }
  footer .copyright2 {
    margin-top: 30px;
    text-align: right;
    font-size: 16px;
    color: #1A2340; }
    footer .copyright2 p {
      margin-bottom: 0; }
    footer .copyright2 a {
      color: #333;
      font-weight: 600; }
  footer .scroll-up {
    position: absolute;
    bottom: 45px;
    right: 50px;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    background-color: #DB353D;
    border: 0;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media only screen and (max-width: 767px) {
      footer .scroll-up {
        display: none; } }
    footer .scroll-up::after {
      content: ' ';
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid #FFFFFF;
      border-bottom: 2px solid #FFFFFF;
      transform: rotate(135deg);
      margin-top: 5px; }
  footer .spacer {
    width: 100%;
    height: 1px;
    background-color: #D9D9D9;
    margin-top: 40px; }
  footer .logo p {
    margin-bottom: 0; }
  footer .logo svg {
    margin-bottom: 19px; }
    @media only screen and (max-width: 767px) {
      footer .logo svg {
        margin-bottom: 30px; } }
  footer p {
    color: #333;
    font-size: 14px;
    font-weight: 400; }
  footer h6 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px; }
    @media only screen and (max-width: 767px) {
      footer h6 {
        margin-bottom: 10px; } }
  footer .contact-item {
    margin-bottom: 56px; }
    footer .contact-item:last-child {
      margin-bottom: 0; }
    footer .contact-item p {
      margin-bottom: 5px; }
    footer .contact-item h6 {
      margin-bottom: 0; }
    footer .contact-item svg {
      margin-right: 10px; }
      footer .contact-item svg path {
        fill: #EFAD00; }
  footer .social {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end; }
    @media only screen and (max-width: 1023px) {
      footer .social {
        margin-top: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px -5px 0 -5px; } }
    footer .social > div {
      margin-bottom: 12px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        footer .social > div {
          display: flex;
          justify-content: space-between;
          flex-direction: column; }
          footer .social > div a {
            margin: 5px; } }
    footer .social a {
      margin: 0 5px; }
    footer .social svg {
      width: 32px;
      height: 32px; }
      footer .social svg path {
        fill: #333; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    @media only screen and (max-width: 767px) {
      footer ul {
        margin-bottom: 30px; } }
    footer ul li {
      width: 50%;
      float: left;
      margin-bottom: 16px; }
      footer ul li a {
        color: #333;
        font-size: 14px;
        font-weight: 400; }

.select-field {
  border: 1px solid #D1D1D1;
  background-color: #FFFFFF;
  color: #FFFFFF;
  font-size: 14px; }
  .select-field a {
    color: #333; }
  .select-field .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow: auto; }
  .select-field .dropdown-toggle::after {
    display: none; }
  .select-field .dropdown-toggle::before {
    content: " ";
    display: block;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
    width: 10px;
    height: 10px;
    transform: rotate(135deg);
    float: right;
    margin-top: 6px; }

.input-wrap {
  width: 100%; }
  .input-wrap input[type=text], .input-wrap input[type=email], .input-wrap input[type=password] {
    height: 50px;
    width: 100%;
    max-width: 500px;
    border: 1px solid #D1D1D1;
    outline: none;
    padding: 0 16px; }
  .input-wrap textarea {
    height: 150px;
    width: 100%;
    max-width: 500px;
    border: 1px solid #D1D1D1;
    padding: 0 16px; }

.input-wrap {
  margin-bottom: 20px; }
  .input-wrap label {
    width: 100%;
    color: #333;
    font-size: 18px; }
  .input-wrap .required {
    border: 1px solid #DB3838 !important; }
  .input-wrap .select-field {
    width: 100%;
    max-width: 500px;
    height: 50px; }
    .input-wrap .select-field .dropdown-toggle {
      height: 50px !important; }
  .input-wrap .checkbox {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    float: left; }
  .input-wrap .checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .checked::after {
      content: " ";
      display: block;
      border-right: 3px solid #EFAD00;
      border-bottom: 3px solid #EFAD00;
      width: 6px;
      height: 12px;
      transform: rotate(45deg); }
  .input-wrap .radio-button {
    border: 2px solid #D1D1D1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 8px;
    border-radius: 50%;
    float: left; }
  .input-wrap .radio-button-checked {
    display: flex;
    align-items: center;
    justify-content: center; }
    .input-wrap .radio-button-checked::after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #EFAD00;
      border-radius: 50%; }

.radio-button-wrap {
  display: flex;
  align-items: center;
  cursor: pointer; }

.sort-form {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  @media only screen and (max-width: 768px) {
    .sort-form {
      justify-content: space-between;
      padding-bottom: 15px; } }
  .sort-form > span {
    font-size: 14px;
    margin-right: 12px; }
  .sort-form .select-field {
    height: 40px;
    width: 200px;
    margin-right: 28px; }
    @media only screen and (max-width: 768px) {
      .sort-form .select-field {
        margin-right: 0; } }
  .sort-form .active {
    background-color: #EFAD00;
    border-radius: 0; }
  @media only screen and (max-width: 768px) {
    .sort-form .btn {
      display: none; } }

.newsletter-form {
  font-size: 18px; }
  .newsletter-form .button {
    margin-right: 16px; }

.article {
  text-decoration: none !important;
  background-color: #F5F5F5;
  /*-webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);*/
  margin-bottom: 20px;
  border-radius: 0;
  min-height: 260px;
  transition: box-shadow 0.2s ease-in-out; }
  @media only screen and (max-width: 1023px) {
    .article {
      min-height: 260px; } }
  .article:hover {
    -webkit-box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 14px 24px 0px rgba(0, 0, 0, 0.1); }
  .article img {
    padding: 10px;
    width: 100%;
    height: 160px;
    object-fit: contain;
    margin-bottom: 20px; }
    @media only screen and (max-width: 768px) {
      .article img {
        height: 160px;
        padding: 5px; } }
  .article .old-price {
    color: #EFAD00;
    text-decoration: line-through;
    font-size: 14px;
    margin-right: 15px; }
  .article .brand-image {
    all: unset;
    position: absolute;
    top: 185px;
    left: 15px;
    width: 50px;
    max-height: 36px;
    object-fit: contain; }
    @media only screen and (max-width: 768px) {
      .article .brand-image {
        top: 70px; } }
  .article .top {
    background-color: #EFAD00;
    position: absolute;
    top: 0;
    left: 0;
    color: #FFFFFF;
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 500; }
  .article .new {
    background-color: #EFAD00;
    position: absolute;
    top: 0;
    right: 0;
    color: #FFFFFF;
    padding: 4px 12px;
    font-size: 16px;
    font-weight: 500; }
  .article h6 {
    padding: 0 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-decoration: none !important;
    height: 38px;
    overflow: hidden;
    margin-bottom: 5px; }
    @media only screen and (max-width: 768px) {
      .article h6 {
        font-size: 16px;
        height: 38px; } }
  .article .bottom-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px; }
    .article .bottom-wrap p {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      margin-bottom: 0;
      white-space: nowrap; }
      .article .bottom-wrap p span {
        color: #9DA5BE;
        font-size: 12px;
        text-decoration: line-through; }
      @media only screen and (max-width: 767px) {
        .article .bottom-wrap p {
          font-size: 14px; } }
    .article .bottom-wrap button {
      border: 0;
      outline: none;
      background-color: #F4F5FB;
      font-size: 12px;
      font-weight: 600;
      color: #0085C1;
      padding: 5px 10px 5px 14px;
      border-radius: 27px;
      white-space: nowrap;
      display: flex;
      align-items: center; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .article .bottom-wrap button {
          display: none; } }
      @media only screen and (min-width: 1200px) and (max-width: 1350px) {
        .article .bottom-wrap button span {
          display: none; } }
      @media only screen and (max-width: 767px) {
        .article .bottom-wrap button span {
          display: none; } }
      .article .bottom-wrap button .isvg {
        display: block; }
      .article .bottom-wrap button svg {
        width: 20px;
        fill: none;
        margin-right: 5px; }
        .article .bottom-wrap button svg path, .article .bottom-wrap button svg circle {
          stroke: #0085C1;
          stroke-width: 2; }

.white-bg-article {
  background-color: #FFFFFF; }

.article-list {
  display: flex;
  min-height: unset; }
  .article-list img {
    width: 147px;
    height: 130px; }
  .article-list .title-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .article-list .title-wrap h6 {
      padding: 0;
      height: unset; }
    .article-list .title-wrap .shortDescription {
      font-size: 14px;
      font-weight: 400;
      color: #333; }
  .article-list .bottom-wrap {
    padding: 20px;
    flex-direction: column; }
    .article-list .bottom-wrap p {
      white-space: nowrap; }
    .article-list .bottom-wrap button {
      white-space: nowrap; }

@media only screen and (max-width: 768px) {
  .article-container:nth-child(2n) {
    padding-left: 7.5px; }
  .article-container:nth-child(2n+1) {
    padding-right: 7.5px; } }

@media only screen and (max-width: 768px) {
  .article-container1:nth-child(2n) {
    padding-right: 7.5px; }
  .article-container1:nth-child(2n+1) {
    padding-left: 7.5px; } }

.alert {
  background-color: #DDF9EB;
  border: 1px solid #EFAD00;
  font-size: 18px;
  min-height: 50px;
  margin-bottom: 34px;
  padding: 10px 18px;
  border-radius: 6px; }

.car-animation {
  position: relative;
  animation-name: car-animation;
  animation-duration: 2s; }

@keyframes car-animation {
  from {
    top: -50px;
    right: -80px;
    transform: scale(0.7); }
  to {
    top: 0px;
    right: 0;
    transform: scale(1); } }

.top-bottom-animation {
  position: relative;
  animation-name: top-bottom;
  animation-duration: 1s; }

@keyframes top-bottom {
  from {
    top: -200px; }
  to {
    top: 0px; } }

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s; }

@keyframes dropdown-animation {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(48px);
    opacity: 1; } }

@keyframes bubble-animation {
  0% {
    border: 10px solid rgba(255, 255, 255, 0); }
  50% {
    border: 20px solid rgba(255, 255, 255, 0.5); }
  100% {
    border: 10px solid rgba(255, 255, 255, 0); } }

.bubble-animation {
  animation-iteration-count: infinite;
  animation-name: bubble-animation;
  animation-duration: 2s; }

@keyframes active-menu-animation {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(70%, 10vh, 0); } }

.active-menu-animation {
  transform: translate3d(70%, 10vh, 0);
  position: fixed;
  width: 100% !important;
  position: fixed !important;
  height: 80vh !important;
  overflow-x: auto;
  overflow-y: scroll;
  animation-name: active-menu-animation;
  animation-duration: 1s;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }

.home-wrap {
  position: relative; }
  .home-wrap::before {
    content: ' ';
    position: absolute;
    left: 30px;
    right: 30px;
    top: -93px;
    background-color: #FFFFFF;
    border-radius: 15px;
    height: 630px;
    z-index: -1; }
    @media only screen and (min-width: 1200px) and (max-width: 1350px) {
      .home-wrap::before {
        height: 570px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .home-wrap::before {
        height: 490px; } }
    @media only screen and (max-width: 1023px) {
      .home-wrap::before {
        display: none; } }
  .home-wrap .main {
    padding-top: 40px;
    padding-bottom: 20px; }
    .home-wrap .main h1 {
      display: none; }
  .home-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .home-wrap .categories .submenu {
      display: none; }
    .home-wrap .categories h3 {
      border-radius: 10px 10px 0 0;
      background-color: #EFAD00;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .home-wrap .categories > ul {
      min-height: 385px; }
    .home-wrap .categories ul {
      border-radius: 0 0 10px 10px;
      width: 100%;
      list-style: none;
      padding: 7px 16px;
      margin: 0;
      border: 3px solid #EFAD00;
      border-top: none;
      float: left; }
      .home-wrap .categories ul > li {
        width: 100%;
        float: left;
        border-bottom: 1px solid #CCCCCC; }
        .home-wrap .categories ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          float: right;
          margin-top: 16px;
          margin-right: 5px; }
        .home-wrap .categories ul > li:last-child {
          border-bottom: 0; }
        .home-wrap .categories ul > li > a {
          float: left;
          color: #333;
          padding: 8px 0;
          /*width: 100%;
                    border-bottom: 1px solid $gray;
                    &:last-child {
                        border-bottom: none;
                    }*/ }
        .home-wrap .categories ul > li:hover .submenu {
          border-radius: 0 10px 10px 0;
          height: 100%;
          position: absolute;
          left: 250px;
          background: white;
          z-index: 16;
          top: 0;
          padding-right: 40px;
          border-top: 3px solid #000;
          border-bottom: 3px solid #000;
          border-right: 3px solid #000;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          min-width: 875px; }
          .home-wrap .categories ul > li:hover .submenu h5 {
            font-size: 22px;
            font-weight: 600;
            line-height: 26px;
            color: #333;
            margin-left: 13px;
            margin-top: 15px; }
          .home-wrap .categories ul > li:hover .submenu li {
            margin-left: 13px;
            float: none;
            display: flex;
            justify-content: space-between;
            width: 236px; }
            .home-wrap .categories ul > li:hover .submenu li:after {
              margin-right: 0px; }
            .home-wrap .categories ul > li:hover .submenu li:last-child {
              border-bottom: 1px solid #CCCCCC;
              margin-bottom: 14px; }
          .home-wrap .categories ul > li:hover .submenu ul {
            border: none; }
  .home-wrap .home-slider {
    height: 400px;
    overflow: hidden;
    background-color: #FFFFFF; }
    @media only screen and (max-width: 767px) {
      .home-wrap .home-slider {
        height: 220px; } }
    @media only screen and (min-width: 768px) and (max-width: 900px) {
      .home-wrap .home-slider {
        height: 300px; } }
    .home-wrap .home-slider .carousel-control-prev {
      background-color: #FFFFFF;
      width: 36px;
      height: 36px;
      position: absolute;
      left: 205px;
      top: 50%;
      margin-top: -18px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-control-prev {
          left: 15px; } }
      .home-wrap .home-slider .carousel-control-prev span {
        display: none; }
      .home-wrap .home-slider .carousel-control-prev::after {
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        border-top: 2px solid #333;
        border-left: 2px solid #333;
        transform: rotate(-45deg); }
    .home-wrap .home-slider .carousel-control-next {
      background-color: #FFFFFF;
      width: 36px;
      height: 36px;
      position: absolute;
      right: 205px;
      top: 50%;
      margin-top: -18px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      display: none; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-control-next {
          right: 15px; } }
      .home-wrap .home-slider .carousel-control-next span {
        display: none; }
      .home-wrap .home-slider .carousel-control-next::after {
        content: ' ';
        display: block;
        width: 12px;
        height: 12px;
        border-bottom: 2px solid #333;
        border-right: 2px solid #333;
        transform: rotate(-45deg); }
    .home-wrap .home-slider .carousel-indicators {
      left: 0;
      justify-content: center;
      margin-right: 0;
      margin-left: 50px; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-indicators {
          margin-left: 20px;
          margin-bottom: 0px;
          display: none; } }
      .home-wrap .home-slider .carousel-indicators li {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #FFFFFF; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-indicators li {
            width: 8px;
            height: 8px; } }
      .home-wrap .home-slider .carousel-indicators .active {
        border: none;
        background-color: #333; }
    .home-wrap .home-slider .carousel-item {
      position: relative; }
      @media only screen and (max-width: 767px) {
        .home-wrap .home-slider .carousel-item {
          padding: 0; } }
      .home-wrap .home-slider .carousel-item img {
        width: 100%;
        height: 400px;
        background-color: #EFAD00; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-item img {
            height: 220px;
            object-fit: cover;
            object-position: right; } }
        @media only screen and (min-width: 768px) and (max-width: 900px) {
          .home-wrap .home-slider .carousel-item img {
            height: 300px; } }
      .home-wrap .home-slider .carousel-item .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 58px 50px; }
        @media only screen and (max-width: 767px) {
          .home-wrap .home-slider .carousel-item .content {
            padding: 20px; } }
        .home-wrap .home-slider .carousel-item .content .content-col {
          text-align: center; }
          @media only screen and (max-width: 767px) {
            .home-wrap .home-slider .carousel-item .content .content-col {
              margin-top: 36px; } }
        .home-wrap .home-slider .carousel-item .content h6 {
          color: #FFFFFF;
          font-weight: 400;
          font-family: 'Nanum Brush Script', cursive;
          font-size: 32px;
          text-align: center;
          margin-bottom: 0; }
          @media only screen and (max-width: 767px) {
            .home-wrap .home-slider .carousel-item .content h6 {
              font-size: 12px; } }
        .home-wrap .home-slider .carousel-item .content h3 {
          color: #FFFFFF;
          font-size: 42px;
          font-weight: 700;
          margin: 0 auto;
          margin-bottom: 11px;
          max-width: 368px;
          text-align: center; }
          @media only screen and (max-width: 767px) {
            .home-wrap .home-slider .carousel-item .content h3 {
              font-size: 16px;
              margin-bottom: 16px;
              max-width: 150px; } }
        .home-wrap .home-slider .carousel-item .content p {
          color: #FFFFFF;
          font-size: 18px;
          margin: 0 auto;
          margin-bottom: 26px;
          max-width: 310px;
          text-align: center; }
          @media only screen and (max-width: 1119px) {
            .home-wrap .home-slider .carousel-item .content p {
              display: none; } }
        .home-wrap .home-slider .carousel-item .content .button {
          color: #FFFFFF;
          background-color: transparent;
          border-radius: 0;
          padding: 15px 28px;
          text-decoration: none;
          font-size: 20px;
          font-weight: 500;
          border: 2px solid #FFFFFF; }
          @media only screen and (max-width: 767px) {
            .home-wrap .home-slider .carousel-item .content .button {
              font-size: 12px;
              padding: 8px; } }
  .home-wrap .section-articles a {
    text-decoration: none; }
  .home-wrap .section-articles .banner img {
    width: 100%;
    margin-bottom: 0px;
    height: 100%;
    padding-bottom: 20px; }
  .home-wrap .section-articles .gray-bg {
    width: 100%;
    background-color: #F5F5F5;
    padding: 20px;
    padding-bottom: 0; }
  .home-wrap .section-articles .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .home-wrap .section-articles .title h2 {
      margin-bottom: 0; }
    .home-wrap .section-articles .title button {
      border-radius: 27px;
      background-color: transparent;
      border: 0;
      outline: none;
      font-size: 14px;
      font-weight: 600;
      color: #333;
      padding: 14px 25px; }
      @media only screen and (max-width: 767px) {
        .home-wrap .section-articles .title button {
          display: none; } }
      .home-wrap .section-articles .title button svg {
        margin-left: 8px; }
        .home-wrap .section-articles .title button svg path {
          fill: #333; }
  .home-wrap .section-articles .buttons-wrap {
    background-color: #FFFFFF;
    padding: 5px;
    float: left;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%; }
    @media only screen and (max-width: 767px) {
      .home-wrap .section-articles .buttons-wrap {
        display: flex;
        max-width: 100vw;
        overflow-x: scroll;
        justify-content: flex-start; } }
    .home-wrap .section-articles .buttons-wrap button {
      background-color: transparent;
      border: 0;
      outline: none;
      font-size: 16px;
      color: #333;
      font-weight: 600;
      padding: 4px 19px;
      border-radius: 0px;
      white-space: nowrap; }
      .home-wrap .section-articles .buttons-wrap button.active {
        background-color: #333;
        color: #FFFFFF; }
  .home-wrap .section-reviews .reviews-slider {
    background-color: #EFAD00;
    padding-top: 30px;
    padding-bottom: 30px; }
    .home-wrap .section-reviews .reviews-slider .carousel-indicators li {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #FFFFFF;
      background-color: transparent; }
    .home-wrap .section-reviews .reviews-slider .carousel-indicators .active {
      border: none;
      background-color: #FFFFFF; }
    .home-wrap .section-reviews .reviews-slider .review img {
      width: 160px;
      height: 160px;
      border-radius: 50%; }
    .home-wrap .section-reviews .reviews-slider .review p {
      color: #FFFFFF;
      margin-top: 11px;
      font-size: 18px; }
    .home-wrap .section-reviews .reviews-slider .review .user {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 600; }
  .home-wrap .section-news {
    border-top: 1px solid #D9D9D9;
    padding: 42px 0; }
    .home-wrap .section-news a {
      text-decoration: none; }
    .home-wrap .section-news .news-title-container h2 {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 15px;
      text-align: center; }
    .home-wrap .section-news .news-title-container h6 {
      color: #6D7587;
      margin-bottom: 40px;
      font-size: 14px;
      font-weight: 400;
      text-align: center; }
    .home-wrap .section-news .button-wrap {
      display: flex;
      justify-content: center;
      align-items: center; }
      .home-wrap .section-news .button-wrap button {
        background-color: #FFFFFF;
        padding: 14px 28px;
        border: 2px solid #333;
        color: #333;
        font-size: 14px;
        font-weight: 600;
        outline: none; }
        .home-wrap .section-news .button-wrap button svg {
          margin-left: 18px; }
          .home-wrap .section-news .button-wrap button svg path {
            fill: #333; }
    .home-wrap .section-news article {
      background-color: #f5f5f5;
      padding-bottom: 24px;
      padding: 10px;
      margin-bottom: 40px; }
      .home-wrap .section-news article img {
        width: 100%;
        height: 200px;
        margin-bottom: 30px; }
      .home-wrap .section-news article h6 {
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
        padding: 0 10px; }
      .home-wrap .section-news article P {
        font-size: 11px;
        color: #A8A8A8;
        margin-bottom: 0;
        padding: 0 10px; }

.slider-container {
  position: relative;
  padding-right: 0;
  padding-left: 0; }
  .slider-container .slick-arrow {
    background-color: #FFFFFF;
    outline: none;
    color: transparent;
    width: 40px;
    height: 40px;
    position: absolute;
    border: 1px solid #D9D9D9; }
    @media only screen and (max-width: 767px) {
      .slider-container .slick-arrow {
        display: none !important; } }
  .slider-container .slick-next {
    top: -58px;
    right: 15px; }
    .slider-container .slick-next::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333;
      border-left: 2px solid #333;
      width: 12px;
      height: 12px;
      transform: rotate(135deg);
      position: absolute;
      top: 14px;
      left: 12px; }
  .slider-container .slick-prev {
    top: -58px;
    left: 15px; }
    .slider-container .slick-prev::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333;
      border-left: 2px solid #333;
      width: 12px;
      height: 12px;
      transform: rotate(-45deg);
      position: absolute;
      top: 14px;
      left: 15px; }
  .slider-container .slick-slider {
    overflow: hidden; }
  .slider-container .slick-list .slick-slide {
    float: left; }
    .slider-container .slick-list .slick-slide > div {
      padding: 0 15px;
      padding-bottom: 20px; }

.brands-container {
  position: relative;
  padding-right: 0;
  padding-left: 0; }
  .brands-container .slick-arrow {
    background-color: #FFFFFF;
    outline: none;
    border: 0;
    color: transparent;
    width: 36px;
    height: 36px;
    position: absolute; }
    @media only screen and (max-width: 767px) {
      .brands-container .slick-arrow {
        display: none !important; } }
  .brands-container .slick-next {
    top: 50%;
    margin-top: -18px;
    right: -50px; }
    .brands-container .slick-next::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333;
      border-left: 2px solid #333;
      width: 12px;
      height: 12px;
      transform: rotate(135deg);
      position: absolute;
      top: 12px;
      left: 12px; }
  .brands-container .slick-prev {
    top: 50%;
    margin-top: -18px;
    left: -50px; }
    .brands-container .slick-prev::after {
      content: ' ';
      display: block;
      border-top: 2px solid #333;
      border-left: 2px solid #333;
      width: 12px;
      height: 12px;
      transform: rotate(-45deg);
      position: absolute;
      top: 12px;
      left: 15px; }
  .brands-container .slick-slider {
    overflow: hidden; }
  .brands-container .slick-list .slick-slide {
    float: left; }
    .brands-container .slick-list .slick-slide > div {
      padding: 0 15px; }
  .brands-container .brand img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    filter: grayscale(100%); }
  .brands-container .brand:hover img {
    filter: none; }

.section-info {
  margin-bottom: 30px; }
  @media only screen and (max-width: 1023px) {
    .section-info {
      display: none; } }
  .section-info .border-div > div {
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    border-top: 1px solid #D9D9D9;
    border-left: 1px solid #d9d9d9; }
  .section-info .info-box > div {
    padding: 24px 21px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-right: 1px solid #d9d9d9; }
    @media only screen and (max-width: 1023px) {
      .section-info .info-box > div {
        background-color: #FFFFFF;
        margin-bottom: 15px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .section-info .info-box > div {
        padding: 14px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .section-info .info-box > div svg {
        width: 24px; } }
    .section-info .info-box > div > div {
      margin-right: 20px; }
    .section-info .info-box > div p {
      margin-bottom: 0;
      font-size: 16px;
      color: #333;
      font-weight: 500; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .section-info .info-box > div p {
          font-size: 12px; } }

.section-banners img {
  width: 100%;
  margin-bottom: 30px; }

.section-categories {
  padding: 30px 0;
  background-color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .section-categories {
      padding: 20px 0;
      margin-bottom: 20px; } }
  .section-categories .category-box {
    text-align: center; }
    .section-categories .category-box img {
      width: 100%;
      height: 77px;
      object-position: center;
      object-fit: contain; }
      @media only screen and (max-width: 767px) {
        .section-categories .category-box img {
          border-radius: 50%;
          height: 70px; } }
    .section-categories .category-box a {
      text-decoration: none !important; }

.news-item a {
  text-decoration: none; }

.news-item article {
  background-color: #f5f5f5;
  padding-bottom: 24px;
  padding: 10px;
  margin-bottom: 40px; }
  .news-item article img {
    width: 100%;
    height: 200px;
    margin-bottom: 30px; }
  .news-item article h6 {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
    padding: 0 10px; }
  .news-item article P {
    font-size: 11px;
    color: #A8A8A8;
    margin-bottom: 0;
    padding: 0 10px; }

.mobile-currency {
  display: none;
  position: fixed;
  right: 0;
  bottom: 50px;
  background-color: #EFAD00;
  border-radius: 6px 0 0 6px;
  padding: 12px 12px;
  color: #FFFFFF; }
  @media only screen and (max-width: 767px) {
    .mobile-currency {
      display: block; } }
  .mobile-currency a {
    color: #FFFFFF;
    font-weight: 600;
    margin: 0 5px; }

.category-wrap {
  position: relative;
  padding-top: 22px; }
  @media only screen and (max-width: 767px) {
    .category-wrap {
      padding-top: 0; } }
  .category-wrap::before {
    content: ' ';
    position: absolute;
    left: 30px;
    right: 30px;
    top: -93px;
    background-color: #FFFFFF;
    border-radius: 15px;
    height: 200px;
    z-index: -1; }
    @media only screen and (max-width: 1023px) {
      .category-wrap::before {
        display: none; } }
  .category-wrap .category-section {
    padding-bottom: 50px; }
    .category-wrap .category-section .info {
      display: flex;
      align-items: center;
      margin-bottom: 64px;
      justify-content: space-between; }
      @media only screen and (max-width: 767px) {
        .category-wrap .category-section .info {
          margin-bottom: 20px; } }
      .category-wrap .category-section .info h1 {
        font-size: 46px;
        font-weight: 700;
        color: #333;
        margin-bottom: 0;
        font-family: 'Arvo', serif; }
        @media only screen and (max-width: 767px) {
          .category-wrap .category-section .info h1 {
            font-size: 22px;
            display: flex;
            align-items: center; }
            .category-wrap .category-section .info h1 svg {
              width: 32px;
              margin-right: 10px; } }
      .category-wrap .category-section .info .bcrumb {
        display: flex;
        align-items: center;
        list-style: none;
        margin: 0;
        padding: 0; }
        @media only screen and (max-width: 1023px) {
          .category-wrap .category-section .info .bcrumb {
            display: none; } }
        .category-wrap .category-section .info .bcrumb li {
          font-size: 14px;
          font-weight: 300;
          color: #333; }
          .category-wrap .category-section .info .bcrumb li a {
            color: #333; }
          .category-wrap .category-section .info .bcrumb li::after {
            content: '/';
            margin: 0 10px; }
          .category-wrap .category-section .info .bcrumb li:last-child a {
            color: #EFAD00; }
          .category-wrap .category-section .info .bcrumb li:last-child::after {
            display: none; }
    @media only screen and (max-width: 767px) {
      .category-wrap .category-section .filters {
        position: fixed;
        top: 200%;
        transition: top 0.3s; } }
    .category-wrap .category-section .filters h3 {
      font-size: 24px;
      font-weight: 600;
      color: #333;
      margin-bottom: 32px; }
    .category-wrap .category-section .filters .filters-top button {
      display: none; }
    .category-wrap .category-section .filters .all-categories {
      font-size: 16px;
      font-weight: 600;
      color: #333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding-left: 20px;
      margin-bottom: 7px;
      text-decoration: none; }
      .category-wrap .category-section .filters .all-categories::before {
        content: ' ';
        position: absolute;
        top: 6px;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        border-left: 2px solid #333;
        border-bottom: 2px solid #333;
        transform: rotate(45deg); }
      .category-wrap .category-section .filters .all-categories span {
        color: #CCCCCC;
        font-weight: 400; }
    .category-wrap .category-section .filters h5 {
      font-size: 20px;
      font-weight: 600;
      color: #EFAD00;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px; }
    .category-wrap .category-section .filters .price-wrap {
      display: flex;
      justify-content: space-between; }
      .category-wrap .category-section .filters .price-wrap div {
        display: flex;
        flex-direction: column; }
        @media only screen and (max-width: 767px) {
          .category-wrap .category-section .filters .price-wrap div {
            width: 45%; } }
      .category-wrap .category-section .filters .price-wrap input {
        width: 90px;
        border: 0;
        height: 46px;
        padding: 8px 10px;
        outline: none;
        background-color: #f5f5f5; }
        @media only screen and (max-width: 767px) {
          .category-wrap .category-section .filters .price-wrap input {
            width: 100%; } }
    .category-wrap .category-section .filters .categories-tree {
      list-style: none;
      padding: 0;
      margin: 0; }
      .category-wrap .category-section .filters .categories-tree li {
        padding: 3px 0px;
        font-size: 16px;
        font-weight: 400;
        color: #333; }
        .category-wrap .category-section .filters .categories-tree li a {
          color: #333;
          text-decoration: none; }
        .category-wrap .category-section .filters .categories-tree li .active {
          color: #EFAD00;
          font-weight: 600; }
        .category-wrap .category-section .filters .categories-tree li .head {
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .category-wrap .category-section .filters .categories-tree li .head button {
            border: 0;
            outline: none;
            background-color: transparent; }
        .category-wrap .category-section .filters .categories-tree li ul {
          list-style: none;
          position: relative;
          padding-left: 20px;
          margin: 0; }
          .category-wrap .category-section .filters .categories-tree li ul::after {
            content: ' ';
            position: absolute;
            top: 0px;
            left: 0px;
            border-left: 1px solid #C7D1E6;
            width: 20px;
            height: 100%;
            height: calc(100% - 14px); }
          .category-wrap .category-section .filters .categories-tree li ul li {
            position: relative; }
            .category-wrap .category-section .filters .categories-tree li ul li::after {
              content: ' ';
              position: absolute;
              top: 14px;
              left: -20px;
              height: 2px;
              width: 10px;
              border-bottom: 1px solid #C7D1E6; }
      .category-wrap .category-section .filters .categories-tree > li {
        font-size: 16px;
        font-weight: 600;
        color: #333; }
    .category-wrap .category-section .filters .categories {
      list-style: none;
      padding: 0;
      margin: 0; }
      .category-wrap .category-section .filters .categories > li > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        text-decoration: none; }
        .category-wrap .category-section .filters .categories > li > a span {
          color: #CCCCCC;
          font-weight: 400; }
      .category-wrap .category-section .filters .categories > li > ul {
        list-style: none;
        padding-left: 30px;
        margin: 9px 0; }
        .category-wrap .category-section .filters .categories > li > ul > li > a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 400;
          color: #333;
          text-decoration: none; }
          .category-wrap .category-section .filters .categories > li > ul > li > a span {
            color: #CCCCCC;
            font-weight: 400; }
    .category-wrap .category-section .filters .spacer {
      width: 100%;
      height: 1px;
      background-color: #D9D9D9;
      margin: 18px 0; }
    .category-wrap .category-section .filters h6 {
      font-size: 16px;
      color: #333;
      font-weight: 600;
      margin-bottom: 15px; }
    .category-wrap .category-section .filters .options {
      list-style: none;
      padding: 0;
      margin: 0; }
      .category-wrap .category-section .filters .options li {
        margin-bottom: 10px; }
        .category-wrap .category-section .filters .options li a {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #333;
          text-decoration: none; }
          .category-wrap .category-section .filters .options li a label {
            margin-bottom: 0;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%; }
            .category-wrap .category-section .filters .options li a label span {
              color: #CCCCCC; }
          .category-wrap .category-section .filters .options li a .check {
            width: 20px;
            min-width: 20px;
            height: 20px;
            border-radius: 6px;
            border: 2px solid #D9D9D9; }
          .category-wrap .category-section .filters .options li a .checked {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center; }
            .category-wrap .category-section .filters .options li a .checked::after {
              content: ' ';
              width: 5px;
              height: 10px;
              border-bottom: 2px solid #0085C1;
              border-right: 2px solid #0085C1;
              display: block;
              transform: rotate(45deg); }
    @media only screen and (max-width: 767px) {
      .category-wrap .category-section .filters-modal {
        top: 0px;
        bottom: 0;
        background-color: #fff;
        z-index: 9999;
        max-height: 100vh;
        overflow-y: scroll;
        padding-top: 64px; }
        .category-wrap .category-section .filters-modal .filters-top {
          position: fixed;
          top: 0px;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: 10px 15px;
          background-color: #fff;
          z-index: 99999; }
          .category-wrap .category-section .filters-modal .filters-top h3 {
            margin-bottom: 0; }
          .category-wrap .category-section .filters-modal .filters-top button {
            background-color: #333;
            border: 0;
            outline: none;
            font-size: 16px;
            font-weight: 500;
            padding: 8px 14px;
            border-radius: 0;
            color: #FFFFFF;
            display: block; } }
  .category-wrap .area .mobile-top {
    margin-bottom: 30px;
    display: none; }
    @media only screen and (max-width: 767px) {
      .category-wrap .area .mobile-top {
        display: block; } }
    .category-wrap .area .mobile-top > div {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .category-wrap .area .mobile-top .filters-button {
      background-color: #fff;
      border: 0;
      outline: none;
      font-size: 16px;
      font-weight: 500;
      padding: 8px 14px;
      border-radius: 6px; }
      .category-wrap .area .mobile-top .filters-button svg {
        margin-left: 20px; }
    .category-wrap .area .mobile-top .sort-wrap {
      display: flex;
      align-items: center; }
      .category-wrap .area .mobile-top .sort-wrap > span {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-right: 11px; }
      .category-wrap .area .mobile-top .sort-wrap .dropdown > button {
        border: 0;
        outline: none;
        box-shadow: none;
        background-color: #f5f5f5;
        color: #333;
        display: flex;
        align-items: center;
        padding: 8px 14px;
        font-size: 16px;
        border-radius: 0; }
        .category-wrap .area .mobile-top .sort-wrap .dropdown > button::after {
          all: unset;
          content: ' ';
          display: block;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid #333;
          border-right: 2px solid #333;
          transform: rotate(45deg);
          margin-left: 20px;
          margin-top: -3px; }
      .category-wrap .area .mobile-top .sort-wrap .dropdown a {
        color: #333; }
  .category-wrap .area .top {
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .category-wrap .area .top {
        display: none; } }
    .category-wrap .area .top h2 {
      font-size: 32px;
      font-weight: 600;
      color: #333;
      margin-bottom: 0;
      display: flex; }
      .category-wrap .area .top h2 svg {
        margin-right: 10px;
        width: 44px; }
    .category-wrap .area .top .sort {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .category-wrap .area .top .sort span {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-right: 22px; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .category-wrap .area .top .sort span {
            display: none; } }
      .category-wrap .area .top .sort ul {
        list-style: none;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0; }
        .category-wrap .area .top .sort ul li {
          text-decoration: none; }
          .category-wrap .area .top .sort ul li button {
            background-color: #F5F5F5;
            color: #333;
            font-size: 16px;
            font-weight: 400;
            border: 0;
            outline: none;
            padding: 8px 20px; }
        .category-wrap .area .top .sort ul .active a button {
          background-color: #333;
          color: #FFFFFF; }
  .category-wrap .area .category-info {
    margin-top: 38px;
    margin-bottom: 25px;
    align-items: center; }
    @media only screen and (max-width: 767px) {
      .category-wrap .area .category-info {
        display: none; } }
    .category-wrap .area .category-info > div {
      display: flex;
      align-items: center; }
    .category-wrap .area .category-info .count {
      font-size: 14px;
      font-weight: 400;
      color: #333;
      margin-right: 20px; }
      .category-wrap .area .category-info .count span {
        font-weight: 600; }
    .category-wrap .area .category-info .display-style {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      .category-wrap .area .category-info .display-style .sort-wrap {
        display: flex;
        align-items: center;
        margin-right: 30px; }
        .category-wrap .area .category-info .display-style .sort-wrap > span {
          font-size: 16px;
          font-weight: 500;
          color: #333;
          margin-right: 11px; }
        .category-wrap .area .category-info .display-style .sort-wrap .dropdown > button {
          border: 0;
          outline: none;
          box-shadow: none;
          background-color: #f5f5f5;
          color: #333;
          display: flex;
          align-items: center;
          padding: 8px 14px;
          font-size: 16px;
          border-radius: 0; }
          .category-wrap .area .category-info .display-style .sort-wrap .dropdown > button::after {
            all: unset;
            content: ' ';
            display: block;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid #333;
            border-right: 2px solid #333;
            transform: rotate(45deg);
            margin-left: 20px;
            margin-top: -3px; }
        .category-wrap .area .category-info .display-style .sort-wrap .dropdown a {
          color: #333; }
      .category-wrap .area .category-info .display-style > button {
        padding: 11px;
        border: 0;
        outline: none;
        background-color: transparent;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .category-wrap .area .category-info .display-style > button svg path, .category-wrap .area .category-info .display-style > button svg rect {
          fill: #333; }
        .category-wrap .area .category-info .display-style > button.active {
          background-color: #333; }
          .category-wrap .area .category-info .display-style > button.active svg path, .category-wrap .area .category-info .display-style > button.active svg rect {
            fill: #FFFFFF; }

.detail-wrap {
  position: relative;
  padding-top: 22px; }
  @media only screen and (max-width: 767px) {
    .detail-wrap {
      padding-top: 0; } }
  .detail-wrap::before {
    content: ' ';
    position: absolute;
    left: 30px;
    right: 30px;
    top: -93px;
    background-color: #FFFFFF;
    border-radius: 15px;
    height: 200px;
    z-index: -1; }
    @media only screen and (max-width: 1023px) {
      .detail-wrap::before {
        display: none; } }
  .detail-wrap .info {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    justify-content: space-between; }
    @media only screen and (max-width: 767px) {
      .detail-wrap .info {
        margin-bottom: 20px; } }
    .detail-wrap .info h1 {
      font-size: 46px;
      font-weight: 700;
      color: #333;
      margin-bottom: 0;
      font-family: 'Arvo', serif; }
      @media only screen and (max-width: 1023px) {
        .detail-wrap .info h1 {
          display: none; } }
    .detail-wrap .info .bcrumb {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0; }
      .detail-wrap .info .bcrumb li {
        font-size: 14px;
        font-weight: 300;
        color: #333; }
        @media only screen and (max-width: 767px) {
          .detail-wrap .info .bcrumb li {
            font-size: 12px; } }
        .detail-wrap .info .bcrumb li a {
          color: #333; }
        .detail-wrap .info .bcrumb li::after {
          content: '/';
          margin: 0 10px; }
          @media only screen and (max-width: 767px) {
            .detail-wrap .info .bcrumb li::after {
              margin: 0 4px; } }
        .detail-wrap .info .bcrumb li:last-child a {
          color: #EFAD00; }
        .detail-wrap .info .bcrumb li:last-child::after {
          display: none; }
  .detail-wrap .section-articles h2 {
    text-align: left;
    font-size: 32px; }
  .detail-wrap .section-articles .slick-prev {
    left: unset;
    right: 80px; }
  .detail-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .detail-wrap .categories h3 {
      background-color: #333;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .detail-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border: 1px solid #333;
      border-top: none;
      float: left;
      width: 100%; }
      .detail-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .detail-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          float: right;
          margin-top: 16px;
          margin-right: 4px; }
        .detail-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .detail-wrap .categories > ul > li a {
          float: left;
          color: #333;
          padding: 7px 0; }
        .detail-wrap .categories > ul > li > a {
          font-size: 18px; }
        .detail-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .detail-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #EFAD00;
            border-left: 2px solid #EFAD00;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .detail-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .detail-wrap .categories > ul .active {
        position: relative; }
        .detail-wrap .categories > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }
  .detail-wrap .article-box {
    margin-bottom: 30px; }
    .detail-wrap .article-box ul {
      list-style: none; }
    .detail-wrap .article-box table {
      width: 100%;
      overflow-x: auto;
      max-width: 100%;
      border: 1px solid #EFAD00; }
      .detail-wrap .article-box table tr th {
        background-color: #EFAD00;
        padding: 16px;
        color: #FFFFFF; }
      .detail-wrap .article-box table tr td {
        border: 1px solid #EFAD00;
        padding: 16px; }
    .detail-wrap .article-box .variations-table {
      max-width: 100%;
      overflow: auto; }
    .detail-wrap .article-box .variations table tr th {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box .variations table tr td {
      font-size: 12px;
      padding: 6px; }
    .detail-wrap .article-box h2 {
      font-size: 32px;
      font-weight: 700; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h2 {
          font-size: 24px; } }
    .detail-wrap .article-box h3 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 19px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box h3 {
          font-size: 20px; } }
    .detail-wrap .article-box .stock {
      font-size: 18px;
      color: #333;
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 42px; }
      @media only screen and (max-width: 767px) {
        .detail-wrap .article-box .stock {
          margin-bottom: 10px; } }
      .detail-wrap .article-box .stock .out-of-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #DE3636;
        float: left;
        margin-right: 14px; }
      .detail-wrap .article-box .stock .in-stock {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #97EF9D;
        float: left;
        margin-right: 14px; }
    .detail-wrap .article-box .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .detail-wrap .article-box .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      font-size: 30px;
      font-weight: 400;
      margin-left: 30px; }
      .detail-wrap .article-box .price span {
        color: #EFAD00;
        font-size: 26px;
        font-weight: 600;
        margin-top: auto; }
        @media only screen and (max-width: 1023px) {
          .detail-wrap .article-box .price span {
            font-size: 22px; } }
        .detail-wrap .article-box .price span .old-price {
          color: #9DA5BE;
          text-decoration: line-through;
          font-size: 16px;
          margin-left: 27px;
          font-weight: 400; }
    .detail-wrap .article-box .short-description {
      font-size: 16px;
      margin-bottom: 49px; }
      @media only screen and (max-width: 767px) {
        .detail-wrap .article-box .short-description {
          margin-bottom: 20px; } }
    .detail-wrap .article-box .actions {
      float: left;
      width: 100%;
      margin-top: 12px;
      display: flex;
      flex-direction: column; }
      .detail-wrap .article-box .actions .wishlist-btn {
        background-color: transparent;
        border: 0;
        outline: none;
        margin-left: 20px;
        padding: 12px 30px;
        border: 2px solid #333; }
        @media only screen and (max-width: 1023px) {
          .detail-wrap .article-box .actions .wishlist-btn {
            width: 100%;
            margin-left: 0; } }
        .detail-wrap .article-box .actions .wishlist-btn svg {
          width: 24px;
          height: 24px; }
          .detail-wrap .article-box .actions .wishlist-btn svg path {
            stroke: #333;
            stroke-width: 3; }
      .detail-wrap .article-box .actions > div {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 30px; }
      .detail-wrap .article-box .actions .quantity-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F5F5F5;
        padding: 0 0 0 20px;
        overflow: hidden; }
        .detail-wrap .article-box .actions .quantity-input > span {
          font-size: 14px; }
        .detail-wrap .article-box .actions .quantity-input > div {
          display: flex;
          align-items: center; }
          .detail-wrap .article-box .actions .quantity-input > div input {
            width: 80px;
            text-align: center;
            font-size: 22px;
            border: 0;
            outline: none;
            height: 30px;
            background-color: #F5F5F5; }
          .detail-wrap .article-box .actions .quantity-input > div > div {
            display: flex;
            flex-direction: column;
            border-left: 1px solid #DBDBDB; }
            .detail-wrap .article-box .actions .quantity-input > div > div button {
              padding: 2px 18px;
              font-size: 18px;
              font-weight: 600;
              height: 23px; }
            .detail-wrap .article-box .actions .quantity-input > div > div .dec {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              outline: none;
              background-color: #F5F5F5; }
            .detail-wrap .article-box .actions .quantity-input > div > div .inc {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0;
              outline: none;
              background-color: #F5F5F5; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .article-box .actions {
          margin-bottom: 0px; } }
      .detail-wrap .article-box .actions .buttons {
        justify-content: space-between;
        margin-top: 30px; }
        @media only screen and (max-width: 767px) {
          .detail-wrap .article-box .actions .buttons {
            margin-top: 0; } }
        @media only screen and (max-width: 1023px) {
          .detail-wrap .article-box .actions .buttons {
            flex-direction: column;
            align-items: flex-start; } }
      .detail-wrap .article-box .actions .button {
        font-size: 14px;
        font-weight: 600;
        background-color: #EFAD00;
        padding: 14px 50px;
        margin-right: 25px; }
        @media only screen and (max-width: 1023px) {
          .detail-wrap .article-box .actions .button {
            width: 100%;
            margin-bottom: 15px; } }
        .detail-wrap .article-box .actions .button svg {
          fill: none; }
          .detail-wrap .article-box .actions .button svg path, .detail-wrap .article-box .actions .button svg circle {
            stroke-width: 2;
            stroke: #FFFFFF; }
      .detail-wrap .article-box .actions .button-black {
        background-color: #333;
        padding: 14px 60px; }
    .detail-wrap .article-box .actions {
      width: 100%;
      float: left;
      display: flex;
      justify-content: space-between; }
      .detail-wrap .article-box .actions .add-review {
        max-width: 170px;
        display: flex;
        align-items: center; }
        .detail-wrap .article-box .actions .add-review .button {
          float: left;
          padding: 10px;
          margin-right: 13px; }
        .detail-wrap .article-box .actions .add-review p {
          margin-bottom: 0; }
      .detail-wrap .article-box .actions .ask-question {
        color: #EFAD00;
        font-size: 18px;
        text-decoration: underline;
        margin-top: auto; }

.article-image {
  position: relative;
  display: flex; }
  @media only screen and (max-width: 767px) {
    .article-image {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 1023px) {
    .article-image {
      flex-direction: row; } }
  .article-image .preview {
    width: 100%;
    width: calc(100% - 116px);
    object-fit: contain;
    object-position: center;
    height: 622px;
    cursor: pointer;
    margin-bottom: 25px;
    margin-left: 22px; }
    @media only screen and (max-width: 1023px) {
      .article-image .preview {
        margin-left: 0;
        width: 100%;
        height: 400px; } }
  .article-image .magnify {
    background-color: transparent;
    border: 0;
    outline: none;
    position: absolute;
    top: 300px;
    right: 20px; }
    @media only screen and (max-width: 767px) {
      .article-image .magnify {
        top: 170px;
        right: 30%;
        margin-right: 20px; } }
    .article-image .magnify svg path {
      fill: #333; }
    .article-image .magnify svg line {
      stroke: #333; }
  .article-image .brand-image {
    position: absolute;
    top: 300px;
    left: 20px;
    width: 100px;
    height: 50px; }
    @media only screen and (max-width: 767px) {
      .article-image .brand-image {
        top: 160px;
        width: 70px;
        max-height: 40px;
        object-fit: contain; } }
  .article-image .mobile-images {
    position: relative;
    width: 100%;
    padding: 0 30px; }
    .article-image .mobile-images .mobile-no-slider-wrap {
      width: 100%;
      display: flex;
      justify-content: center; }
      .article-image .mobile-images .mobile-no-slider-wrap .image {
        padding: 12px 0;
        cursor: pointer;
        width: 80px; }
        .article-image .mobile-images .mobile-no-slider-wrap .image img {
          width: 100%;
          object-fit: cover; }
        .article-image .mobile-images .mobile-no-slider-wrap .image.active img {
          border: 4px solid #EFAD00; }
    .article-image .mobile-images .slick-arrow {
      background-color: transparent;
      outline: none;
      border: 0;
      color: transparent;
      width: 36px;
      height: 36px;
      position: absolute;
      border-radius: 0; }
    .article-image .mobile-images .slick-next {
      top: 50%;
      margin-top: -18px;
      right: 0; }
      .article-image .mobile-images .slick-next::after {
        content: ' ';
        display: block;
        border-top: 2px solid #EFAD00;
        border-left: 2px solid #EFAD00;
        width: 16px;
        height: 16px;
        transform: rotate(135deg);
        position: absolute;
        top: 12px;
        left: 12px; }
    .article-image .mobile-images .slick-prev {
      top: 50%;
      margin-top: -18px;
      left: 0; }
      .article-image .mobile-images .slick-prev::after {
        content: ' ';
        display: block;
        border-top: 2px solid #EFAD00;
        border-left: 2px solid #EFAD00;
        width: 16px;
        height: 16px;
        transform: rotate(-45deg);
        position: absolute;
        top: 12px;
        left: 15px; }
    .article-image .mobile-images .slick-slider {
      overflow: hidden; }
    .article-image .mobile-images .slick-list .slick-slide {
      float: left; }
      .article-image .mobile-images .slick-list .slick-slide .image {
        padding: 0 12px;
        cursor: pointer; }
        .article-image .mobile-images .slick-list .slick-slide .image img {
          width: 100%;
          height: 76px;
          object-fit: cover;
          border-radius: 0; }
        .article-image .mobile-images .slick-list .slick-slide .image.active img {
          border: 4px solid #EFAD00; }
  .article-image .images {
    position: relative;
    width: 115px;
    padding: 30px 0; }
    @media only screen and (max-width: 1023px) {
      .article-image .images {
        width: 50px; } }
    .article-image .images .slick-arrow {
      background-color: transparent;
      outline: none;
      border: 0;
      color: transparent;
      width: 36px;
      height: 36px;
      position: absolute;
      border-radius: 0; }
    .article-image .images .slick-next {
      bottom: 0;
      left: 50%;
      margin-left: -22px; }
      .article-image .images .slick-next::after {
        content: ' ';
        display: block;
        border-top: 2px solid #EFAD00;
        border-left: 2px solid #EFAD00;
        width: 16px;
        height: 16px;
        transform: rotate(-135deg);
        position: absolute;
        top: 12px;
        left: 12px; }
    .article-image .images .slick-prev {
      top: 0;
      left: 50%;
      margin-left: -22px; }
      .article-image .images .slick-prev::after {
        content: ' ';
        display: block;
        border-top: 2px solid #EFAD00;
        border-left: 2px solid #EFAD00;
        width: 16px;
        height: 16px;
        transform: rotate(45deg);
        position: absolute;
        top: 12px;
        left: 15px; }
    .article-image .images .slick-slider {
      overflow: hidden; }
    .article-image .images .slick-list {
      overflow: hidden; }
      .article-image .images .slick-list .slick-slide {
        float: left; }
        .article-image .images .slick-list .slick-slide .image {
          padding: 12px 0;
          cursor: pointer; }
          .article-image .images .slick-list .slick-slide .image img {
            width: 100%;
            height: 100px;
            object-fit: cover; }
          .article-image .images .slick-list .slick-slide .image.active img {
            border: 4px solid #EFAD00; }
    .article-image .images .no-slider-wrap .image {
      padding: 12px 0;
      cursor: pointer; }
      .article-image .images .no-slider-wrap .image img {
        width: 100%;
        object-fit: cover; }
      .article-image .images .no-slider-wrap .image.active img {
        border: 4px solid #EFAD00; }

.packages {
  margin: 0 -5px;
  margin-top: 12px;
  width: 100%;
  float: left;
  cursor: pointer; }
  .packages .package {
    background-color: #F3F3F3;
    float: left;
    padding: 10px 8px;
    margin: 0 5px;
    margin-bottom: 10px; }
    .packages .package p {
      margin-bottom: 6px;
      color: #999999;
      font-size: 16px; }
      .packages .package p:nth-child(2) {
        margin-bottom: 0; }
  .packages .active p {
    margin-bottom: 6px;
    color: #999999;
    font-size: 16px; }
    .packages .active p:nth-child(2) {
      margin-bottom: 0;
      font-weight: 600;
      color: #EFAD00; }

.article-detail {
  min-height: 420px; }

.with-pdv {
  text-align: right;
  font-size: 14px; }

.carousel-detail {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999; }
  .carousel-detail > button {
    outline: none;
    border: 0;
    background-color: transparent;
    position: fixed;
    top: 30px;
    right: 30px;
    color: #FFFFFF;
    font-size: 64px;
    z-index: 99999; }
    @media only screen and (max-width: 1200px) {
      .carousel-detail > button {
        top: 16px;
        right: 16px;
        font-size: 48px; } }
    .carousel-detail > button svg {
      fill: #FFFFFF; }
  .carousel-detail .carousel {
    width: 100%;
    height: 100%; }
    .carousel-detail .carousel .carousel-inner {
      height: 100%; }
    .carousel-detail .carousel .carousel-item {
      text-align: center;
      height: 100%; }
    .carousel-detail .carousel img {
      width: 50%;
      height: 95%;
      padding-top: 2.5%;
      object-fit: contain;
      object-position: center;
      margin: 0 auto; }
      @media only screen and (max-width: 1200px) {
        .carousel-detail .carousel img {
          width: 100%;
          height: 100%;
          padding-top: 0; } }
    .carousel-detail .carousel .carousel-control-prev {
      position: absolute;
      background-color: #FFFFFF;
      opacity: 1;
      left: 0;
      top: 50%;
      height: 60px;
      width: 40px;
      margin-top: -30px; }
      .carousel-detail .carousel .carousel-control-prev::after {
        content: ' ';
        border-left: 2px solid #333;
        border-top: 2px solid #333;
        transform: rotate(-45deg);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 14px; }
    .carousel-detail .carousel .carousel-control-next {
      position: absolute;
      background-color: #FFFFFF;
      opacity: 1;
      right: 0;
      top: 50%;
      height: 60px;
      width: 40px;
      margin-top: -30px; }
      .carousel-detail .carousel .carousel-control-next::after {
        content: ' ';
        border-left: 2px solid #333;
        border-top: 2px solid #333;
        transform: rotate(135deg);
        width: 12px;
        height: 12px;
        position: absolute;
        top: 24px;
        left: 14px; }

.account-wrap {
  padding-top: 40px; }
  .account-wrap h1 {
    font-size: 32px;
    font-weight: 600; }
  .account-wrap .signout-btn {
    background-color: #EFAD00;
    border-radius: 10px; }
  .account-wrap .categories {
    background-color: #FFFFFF;
    float: left;
    width: 100%; }
    .account-wrap .categories h3 {
      background-color: #333;
      color: #FFFFFF;
      padding: 15px 22px;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0; }
    .account-wrap .categories > ul {
      width: 100%;
      list-style: none;
      padding: 3px 16px;
      margin: 0;
      border: 1px solid #333;
      border-top: none;
      float: left;
      width: 100%; }
      .account-wrap .categories > ul > li {
        border-bottom: 1px solid #CCCCCC;
        width: 100%;
        float: left; }
        .account-wrap .categories > ul > li::after {
          content: " ";
          display: block;
          border-top: 2px solid #CCCCCC;
          border-right: 2px solid #CCCCCC;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          float: right;
          margin-top: 16px;
          margin-right: 4px; }
        .account-wrap .categories > ul > li:last-child {
          border-bottom: none; }
        .account-wrap .categories > ul > li a {
          float: left;
          color: #333;
          padding: 7px 0; }
        .account-wrap .categories > ul > li > a {
          font-size: 18px; }
        .account-wrap .categories > ul > li ul {
          width: 100%;
          float: left;
          list-style: none;
          padding-left: 14px;
          position: relative; }
          .account-wrap .categories > ul > li ul::after {
            content: " ";
            display: block;
            border-bottom: 2px solid #EFAD00;
            border-left: 2px solid #EFAD00;
            width: 8px;
            height: 8px;
            margin-top: 14px;
            position: absolute;
            top: 0;
            left: 0; }
          .account-wrap .categories > ul > li ul li {
            width: 100%;
            float: left; }
      .account-wrap .categories > ul .active {
        position: relative; }
        .account-wrap .categories > ul .active::after {
          position: absolute;
          top: 0;
          right: 0;
          transform: rotate(135deg); }
  .account-wrap .account-info p {
    font-size: 18px; }
  .account-wrap .box-container {
    background-color: #FFFFFF;
    margin-bottom: 30px;
    padding-top: 40px;
    padding-bottom: 26px;
    margin-top: 10px;
    border-radius: 15px; }
    @media only screen and (max-width: 768px) {
      .account-wrap .box-container {
        padding: 15px;
        padding-bottom: 26px; } }
    .account-wrap .box-container h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container h6 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px; }
    .account-wrap .box-container .lost-password {
      color: #333; }
    .account-wrap .box-container table {
      width: 100%;
      border: 1px solid #EFAD00; }
      .account-wrap .box-container table tr th {
        background-color: #EFAD00;
        padding: 16px;
        color: #FFFFFF; }
      .account-wrap .box-container table tr td {
        border: 1px solid #EFAD00;
        padding: 16px; }
    .account-wrap .box-container .button-group {
      padding: 0 0 20px 0; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .button-group {
          padding: 0 15px; } }
      .account-wrap .box-container .button-group .button {
        margin-right: 20px; }
        @media only screen and (max-width: 991px) {
          .account-wrap .box-container .button-group .button {
            margin-right: 0;
            margin-bottom: 16px;
            width: 100%; } }
    .account-wrap .box-container .table {
      border: 1px solid #D1D1D1; }
      @media only screen and (max-width: 991px) {
        .account-wrap .box-container .table {
          margin: 0 15px;
          margin-bottom: 16px; } }
      .account-wrap .box-container .table .row > div {
        padding: 15px; }
        .account-wrap .box-container .table .row > div P {
          margin-bottom: 0;
          font-size: 18px; }
      .account-wrap .box-container .table .header {
        background-color: #EFAD00;
        color: #FFFFFF;
        font-weight: 600;
        padding: 0;
        height: 34px; }
        .account-wrap .box-container .table .header > div {
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-size: 14px; }
      .account-wrap .box-container .table .border-row {
        border-bottom: 1px solid #D1D1D1; }
        .account-wrap .box-container .table .border-row:last-child {
          border-bottom: 0; }
        .account-wrap .box-container .table .border-row > div {
          border-right: 1px solid #D1D1D1; }
          .account-wrap .box-container .table .border-row > div:last-child {
            border-right: 0; }
    .account-wrap .box-container .spacer {
      width: 100%;
      background-color: #CCCCCC;
      height: 1px;
      margin: 18px 0;
      float: left; }
    .account-wrap .box-container a {
      text-decoration: none !important; }
      .account-wrap .box-container a .box {
        background-color: #f5f5f5;
        border-radius: 6px;
        padding: 20px 40px;
        text-align: center;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 20px;
        text-decoration: none !important; }
        .account-wrap .box-container a .box p {
          margin-top: 15px;
          margin-bottom: 0;
          color: #333;
          font-size: 18px;
          line-height: 1;
          font-weight: 500;
          text-decoration: none !important; }

.find-client-box-container {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  padding: 40px;
  padding-bottom: 26px;
  margin-top: 10px; }
  @media only screen and (max-width: 768px) {
    .find-client-box-container {
      padding: 15px;
      padding-bottom: 26px; } }

.cart-wrap .table {
  border: 1px solid #D1D1D1; }
  @media only screen and (max-width: 991px) {
    .cart-wrap .table {
      margin-left: 0 !important;
      margin-right: 0 !important;
      margin-bottom: 16px; } }

.cart-wrap .cart-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 0; }
  .cart-wrap .cart-progress > li {
    position: relative;
    text-align: center; }
    .cart-wrap .cart-progress > li::before {
      content: " ";
      position: absolute;
      display: block;
      height: 6px;
      width: 98px;
      background-color: #CCCCCC;
      border-radius: 13px;
      left: 100%;
      margin-left: -15px;
      top: 21px; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li::before {
          left: 150%;
          width: 30px; } }
      @media only screen and (max-width: 400px) {
        .cart-wrap .cart-progress > li::before {
          display: none; } }
      @media only screen and (min-width: 768px) and (max-width: 1112px) {
        .cart-wrap .cart-progress > li::before {
          width: 70px; } }
    .cart-wrap .cart-progress > li::after {
      content: " ";
      position: absolute;
      height: 6px;
      width: 0;
      background-color: #EFAD00;
      border-radius: 13px;
      left: 100%;
      margin-left: -15px;
      top: 21px;
      -webkit-transition: width 0.5s;
      /* For Safari 3.1 to 6.0 */
      transition: width 0.5s; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li::after {
          left: 150%; } }
      @media only screen and (max-width: 400px) {
        .cart-wrap .cart-progress > li::after {
          display: none; } }
    .cart-wrap .cart-progress > li:last-child::before {
      display: none; }
    .cart-wrap .cart-progress > li:last-child::after {
      display: none; }
    .cart-wrap .cart-progress > li .icon {
      margin: 0 auto;
      margin-bottom: 16px;
      border: 4px solid #CCCCCC;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .cart-wrap .cart-progress > li .icon svg {
        fill: #EFAD00;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
    .cart-wrap .cart-progress > li p {
      max-width: 140px;
      text-align: center;
      margin-bottom: 0;
      font-size: 18px; }
      @media only screen and (max-width: 420px) {
        .cart-wrap .cart-progress > li p {
          display: none; } }
      .cart-wrap .cart-progress > li p:nth-child(2) {
        font-size: 18px;
        font-weight: 600; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-progress > li p:nth-child(3) {
          display: none; } }
    .cart-wrap .cart-progress > li:nth-child(1) svg {
      fill: #EFAD00; }
  .cart-wrap .cart-progress .active::after {
    width: 49px; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .active::after {
        width: 35px; } }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress .active::after {
        width: 15px; } }
  .cart-wrap .cart-progress .active .icon {
    background-color: #EFAD00;
    border: 0; }
  .cart-wrap .cart-progress .active:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .active:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }
  .cart-wrap .cart-progress .done::after {
    width: 98px;
    display: block; }
    @media only screen and (min-width: 768px) and (max-width: 1112px) {
      .cart-wrap .cart-progress .done::after {
        width: 70px; } }
    @media only screen and (max-width: 767px) {
      .cart-wrap .cart-progress .done::after {
        width: 30px; } }
  .cart-wrap .cart-progress .done .icon {
    background-color: #EFAD00;
    border: 0; }
  .cart-wrap .cart-progress .done:nth-child(1) svg {
    stroke: #FFFFFF; }
  .cart-wrap .cart-progress .done:nth-child(4) svg {
    fill: #FFFFFF;
    stroke: unset; }

@media only screen and (max-width: 991px) {
  .cart-wrap .button-group {
    padding: 0 !important; } }

.cart-wrap .back {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 16px;
  height: 40px; }
  .cart-wrap .back::before {
    content: " ";
    display: block;
    border-bottom: 2px solid #EFAD00;
    border-right: 2px solid #EFAD00;
    transform: rotate(135deg);
    width: 8px;
    height: 8px;
    margin-right: 6px; }

.cart-wrap .checkout-button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; }
  .cart-wrap .checkout-button .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-button .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .checkout-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px; }
  .cart-wrap .checkout-buttons span {
    padding: 0 16px; }
  .cart-wrap .checkout-buttons .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .cart-wrap .checkout-buttons .button::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #FFFFFF;
      border-right: 2px solid #FFFFFF;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      float: right;
      margin-left: 8px; }

.cart-wrap .notes-input .input-wrap {
  margin-bottom: 0; }
  .cart-wrap .notes-input .input-wrap input[type=text] {
    height: 36px; }

.cart-wrap .coupon-input .input-wrap {
  margin-bottom: 0;
  margin-bottom: 32px; }
  .cart-wrap .coupon-input .input-wrap input[type=text] {
    height: 36px;
    width: 120px; }

.cart-wrap .cart-table {
  border: 1px solid #D1D1D1;
  border-bottom: 0;
  margin-bottom: 24px; }
  .cart-wrap .cart-table .header {
    background-color: #333;
    height: 34px;
    display: flex;
    align-items: center; }
    .cart-wrap .cart-table .header > div {
      color: #FFFFFF;
      font-weight: 600; }
      @media only screen and (max-width: 767px) {
        .cart-wrap .cart-table .header > div {
          font-size: 10px; } }
    .cart-wrap .cart-table .header .number {
      text-align: right; }
  .cart-wrap .cart-table .article-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .article-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D1D1D1;
      border-bottom: 1px solid #D1D1D1;
      height: 60px;
      display: flex;
      align-items: center; }
      .cart-wrap .cart-table .article-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .article-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .article-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .article-info input[type=text] {
      height: 36px;
      width: 50px;
      border-radius: 6px;
      border: 1px solid #D1D1D1;
      padding: 0 16px;
      margin-right: 8px; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info input[type=text] {
          width: 30px;
          padding: 0 8px; } }
    .cart-wrap .cart-table .article-info .number {
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .article-info .delete {
      justify-content: center; }
    .cart-wrap .cart-table .article-info label {
      margin-bottom: 0; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .article-info label {
          display: none; } }
    .cart-wrap .cart-table .article-info img {
      width: 44px;
      height: 33px;
      margin-right: 12px; }
  .cart-wrap .cart-table .payment-info {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-wrap .cart-table .payment-info > div {
      padding: 12px 8px;
      border-right: 1px solid #D1D1D1;
      border-bottom: 1px solid #D1D1D1;
      height: 500px; }
      .cart-wrap .cart-table .payment-info > div:last-child {
        border-right: 0; }
    .cart-wrap .cart-table .payment-info .select-field {
      width: 100%; }
      @media only screen and (max-width: 991px) {
        .cart-wrap .cart-table .payment-info .select-field {
          font-size: 10px; } }
    @media only screen and (max-width: 991px) {
      .cart-wrap .cart-table .payment-info {
        font-size: 12px; } }
    .cart-wrap .cart-table .payment-info .number {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px; }
    .cart-wrap .cart-table .payment-info .delete {
      display: flex;
      justify-content: center; }
    .cart-wrap .cart-table .payment-info label {
      margin-bottom: 0; }
    .cart-wrap .cart-table .payment-info img {
      width: 44px;
      height: 33px;
      margin-right: 12px; }
